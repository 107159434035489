import { sha256 } from 'hash.js';
import { print } from 'graphql/language/printer';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { createHttpLink, gql, ApolloClient, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { RestLink } from 'apollo-link-rest';
import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-unfetch';
import config from '../config';
import i18n from './i18n';
import Bugsnag from './bugsnag';
import {
  promiseToObservable,
  refreshToken,
  setHeadersWithToken,
  getTokenFromCookies,
  hostname
} from './helpers';

const isBrowser = typeof window !== 'undefined';
let apolloClient = null;

// Polyfill fetch() on the server (used by apollo-client)
if (typeof window === 'undefined') {
  global.fetch = fetch;
}

function create(initialState, { lang, bridgeState }) {
  let ObjForErrorLink = {
    isFirst: true,
    promise: null
  };
  let ObjForAuthLink = {
    isFirst: true,
    promise: null
  };
  const persistedQueryLink = createPersistedQueryLink({
    useGETForHashedQueries: true,
    generateHash: query => {
      return sha256().update(print(query)).digest('hex');
    }
  });

  const restLink = new RestLink({
    uri: `${config.protocol}://${hostname(lang, config.host)}/`
  });

  const httpLink = createHttpLink({
    uri: `${config.protocol}://${hostname(lang, config.host)}/graphql`,
    credentials: 'include',
    fetch: isBrowser && fetch
  });

  const authLink = setContext(() => {
    if (!isBrowser || (isBrowser && getTokenFromCookies()))
      return setHeadersWithToken();
    if (ObjForAuthLink.isFirst) {
      ObjForAuthLink.promise = refreshToken(i18n);
      ObjForAuthLink.isFirst = false;
    }
    return Promise.all([ObjForAuthLink.promise]).then(() => {
      return setHeadersWithToken();
    });
  });

  const headersMiddleware = new ApolloLink((operation, forward) => {
    const platform = bridgeState && (bridgeState?.isWebView ? 'app' : 'mobile');

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...(platform && { 'X-Platform': platform })
      }
    }));

    return forward(operation);
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        Bugsnag.notify(new Error('GraphQlError'), {
          beforeSend(report) {
            const reportData = report;
            graphQLErrors.map(({ message, locations, path }) => {
              reportData.metaData = {
                'Error Message': {
                  message,
                  locations,
                  path
                }
              };

              return reportData;
            });
          }
        });

      if (networkError && networkError.statusCode === 401) {
        if (ObjForErrorLink.isFirst) {
          ObjForErrorLink.promise = refreshToken(i18n);
          ObjForErrorLink.isFirst = false;
        }

        return promiseToObservable(
          Promise.all([ObjForErrorLink.promise])
        ).flatMap(() => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: setHeadersWithToken()
            }
          });
          ObjForAuthLink = {
            isFirst: true,
            promise: null
          };
          ObjForErrorLink = {
            isFirst: true,
            promise: null
          };
          return forward(operation);
        });
      }
    }
  );

  const link = errorLink
    .concat(restLink)
    .concat(authLink)
    .concat(headersMiddleware)
    .concat(persistedQueryLink)
    .concat(httpLink);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          items: {
            keyArgs: ['filter', 'sort'],
            merge: (existing = [], incoming, { args }) => {
              return !args?.offset ? incoming : [...existing, ...incoming];
            }
          },
          itemsConnection: relayStylePagination(['filter', 'sort'])
        }
      },
      PaymentMethod: {
        keyFields: ['name']
      }
    }
  }).restore(initialState || {});
  const defaultData = {
    rooms: [
      {
        id: '1',
        slug: '1-otaqli',
        name: '1 комната',
        __typename: 'Room'
      },
      {
        id: '2',
        slug: '2-otaqli',
        name: '2 комнаты',
        __typename: 'Room'
      },
      {
        id: '3',
        slug: '3-otaqli',
        name: '3 комнаты',
        __typename: 'Room'
      },
      {
        id: '4',
        slug: '4-otaqli',
        name: '4 комнаты',
        __typename: 'Room'
      },
      {
        id: '5+',
        slug: '5-otaqli',
        name: '',
        __typename: 'Room'
      }
    ],
    type: [
      {
        id: 'false',
        name: 'Alqi-satqi',
        __typename: 'Type'
      },
      {
        id: 'true',
        name: 'Kiraye',
        __typename: 'Type'
      }
    ],
    sort: [
      {
        id: '1',
        params: 'bumped_at+desc',
        name: 'BUMPED_AT_DESC',
        __typename: 'Sort'
      },
      {
        id: '2',
        params: 'area',
        name: 'AREA_ASC',
        __typename: 'Sort'
      },
      {
        id: '3',
        params: 'price+asc',
        name: 'PRICE_ASC',
        __typename: 'Sort'
      },
      {
        id: '4',
        params: 'price+desc',
        name: 'PRICE_DESC',
        __typename: 'Sort'
      }
    ]
  };

  if (!process.browser) {
    const query = gql`
      query defaultQuery {
        rooms @client {
          id
          slug
          name
        }
        type @client {
          id
          name
        }
        sort @client {
          id
          params
          name
        }
      }
    `;

    cache.writeQuery({
      query,
      data: defaultData
    });
  }

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link,
    cache,
    resolvers: {}
  });
}

export default function initApollo(initialState, options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return create(initialState, {
      ...options
    });
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
