import React from 'react';
import PropTypes from 'prop-types';
import { getDataFromTree } from '@apollo/client/react/ssr';
import initApollo from './init-Apollo';
import i18n from './i18n';
import Bugsnag from './bugsnag';

async function middleware({ ctx: { req } }) {
  if (!process.browser) {
    const host = req.headers['x-forwarded-host'] || req.headers.host;
    const currentLang = host.split('.')[0];

    i18n.changeLanguage(currentLang);
  }
}

const withApolloClient = App =>
  class Apollo extends React.Component {
    static displayName = 'withApollo(App)';

    static async getInitialProps(ctx) {
      await middleware(ctx);
      const {
        Component,
        router,
        ctx: { res, AppTree }
      } = ctx;
      const lng = i18n.language;

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = initApollo(
        {},
        {
          lang: lng
        }
      );

      ctx.ctx.apolloClient = apollo;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {};
      }

      if (!process.browser) {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <AppTree
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={apollo}
            />
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          console.error('Error while running `getDataFromTree`', error);
          Bugsnag.notify(error);
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
        lng
      };
    }

    static propTypes = {
      apolloState: PropTypes.object,
      bridgeState: PropTypes.object
    };

    constructor(props) {
      super(props);
      this.apolloClient = initApollo(props.apolloState, {
        lang: i18n.language,
        bridgeState: props.bridgeState
      });
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };

export default withApolloClient;
